<template>
  <div>
    <ayl-berad-nav :nav='customer' v-if="this.nav ==='customer'"></ayl-berad-nav>
    <ayl-berad-nav :nav='driver' v-if="this.nav ==='driver'"></ayl-berad-nav>
    <ayl-berad-nav :nav='car' v-if="this.nav ==='car'"></ayl-berad-nav>
    <ayl-berad-nav :nav='business_type' v-if="this.nav ==='business_type'"></ayl-berad-nav>
    <ayl-berad-nav :nav='site' v-if="this.nav ==='site'"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
        <div slot="ctrl-button" style="margin-bottom: 12px;display: flex;justify-content: space-between">
          <el-button type="primary" plain @click="dialogExport=true">导出</el-button>
          <el-button @click="$router.go(-1)">返 回</el-button>
        </div>
        <ayl-table :table="table">
        </ayl-table>
      </div>
    </div>
    <!--导出-->
    <el-dialog title="选择导出的数据" :visible.sync="dialogExport" width="540px" center>
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      <el-checkbox-group v-model="checkIndex" @change="handleCheckedCitiesChange">
        <el-checkbox :label="item.index" v-for="(item, idx) in checkList" :key="idx">{{item.value}}</el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="exportExcel" :loading="btnLoading">确 定</el-button>
        <el-button @click="dialogExport=false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import mixins from '../../utils/mixins'
  export default {
    mixins: [mixins.$exportCheckAll],
    data () {
      const vm = this
      return {
        dialogExport: false,
        checkList: [],
        checkIndex: [],
        btnLoading: false,
        nav: null,
        customer: [{name: "业务报表", path: ""}, {name: "客户报表", path: "go(-1)"}, {name: "订单明细", path: ""}],
        driver: [{name: "业务报表", path: ""}, {name: "驾驶员报表", path: "/business-report/driver-report"}, {name: "订单明细", path: ""}],
        car: [{name: "业务报表", path: ""}, {name: "车辆报表", path: "/business-report/vehicl-report"}, {name: "订单明细", path: ""}],
        business_type: [{name: "业务报表", path: ""}, {name: "业务类型报表", path: "/business-report/business-type-report"}, {name: "订单明细", path: ""}],
        site: [{name: "业务报表", path: ""}, {name: "地点报表", path: "/business-report/site-report"}, {name: "订单明细", path: ""}],
        table: {
          api: vm.$api.reportDetails,
          query: {
            startTime: null,
            endTime: null,
            orderType: null,
            carBelong: null,
            queryContent: null,
            queryType: null,
          },
          columns: [
            {
              title: '订单编号',
              width: '160px',
              key: 'orderId',
            }, {
              title: '车牌号码',
              width: '100px',
              key: 'plateNumber',
            }, {
              title: '驾驶员',
              width: '90px',
              key: 'driverName',
            }, 
            {
              title: '业务类型',
              width: '100px',
              render (h, ctx) {
                return h('span', [
                  h('span', {
                    style: {
                      color: ctx.row.orderType === '直拨' ? vm.$config.color_z : ctx.row.orderType === '内转' ? vm.$config.color_n : vm.$config.color_w
                    }
                  }, ctx.row.orderType||'- -')
                ])
              }
            }, {
              title: '客户名称',
              key: 'partnerName',
            }, {
              title: '结算客户',
              width: '120px',
              key: 'accountName',
            }, {
              title: '起运地',
              key: 'sendPlace',
            }, {
              title: '目的地',
              key: 'receivePlace',
            }, {
              title: '运输日期',
              width: '150px',
              filter: 'str2ymd',
              key: 'transportTime',
            }, {
              title: '重量(吨)',
              width: '80px',
              key: 'weight',
            }, {
              title: '收入',
              width: '90px',
              key: 'income',
            }, {
              title: '成本',
              width: '90px',
              key: 'cost',
            },
            vm.$route.query.type ==='customer' ? {
              title: '信息费',
              width: '150px',
              key: 'messageFee',
            } : '',
            vm.$route.query.type ==='customer' ? {
              title: '代垫费用',
              width: '150px',
              key: 'advanceFee',
            } : '',
            {
              title: '操作',
              width: '155px',
              render (h, ctx) {
                return h('span', [
                  h('span', {
                    class: {
                      'table-view': true,
                    },
                    on: {
                      click: vm.goEdit.bind(this, ctx.row)
                    }
                  }, '详情')
                ])
              }
            }
            ]
        }
      }
    },
    methods: {
      //订单详情
      goEdit(val){
        this.$router.push({
          path: '/business-report/search-derails',
          query: {
            orderId: val.orderId,
            type: this.$route.query.type,
          }
        })
      },
      // 导出
      async exportExcel(){
        this.btnLoading = true
        try {
          const url = await this.$api.excelAll({
            params: {
              startTime: this.table.query.startTime,
              endTime: this.table.query.endTime,
              orderType: this.table.query.orderType,
              carBelong: this.table.query.carBelong,
              queryContent: this.table.query.queryContent,
              queryType: parseInt(this.table.query.queryType),
            },
            excelKey: 'SteelReposrtOrderDetail',
            indexList: this.checkIndex,
          })
          window.open(url)
          this.dialogExport = false
        }catch (e) {}
        this.btnLoading = false
      },
    },
    async mounted () {
      this.table.query.startTime =this.$route.query.startTime,
      this.table.query.endTime =this.$route.query.endTime,
      this.table.query.orderType =this.$route.query.orderType,
      this.table.query.carBelong =this.$route.query.carBelong,
      this.table.query.queryContent =this.$route.query.queryContent,
      this.nav = this.$route.query.type,
      this.table.query.queryType = this.$route.query.queryType,
      // await this.$search(this.table),
      // 导出
      this.$api.GetExcelExportKeyAndColumn({
        excelKey: "SteelReposrtOrderDetail"
      }).then((res)=>{
        this.checkList = res.chsList
        this.checkIndex = this.checkList.map(e=>{
          return e.index
        })
      })
    }
  }
</script>

<style lang='sass' scoped>

</style>
